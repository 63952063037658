@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Noto Sans', sans-serif;
  background-color: #f8f8ff;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(100, 99, 99, 0.7);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

input[type="color"] {
  background: none;
  box-shadow: none;
  border: none;
}